import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <p className="pHome home-about-body" style={{padding:'1rem'}}>Software engineering student at the State University in Novi Pazar and a web developer </p>
  );
}

export default Type;
